import { useMediaQuery, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BannerContainer, BannerContent, BannerTitle, BannerDescription, BannerShopButton } from "../../styles/banner";

// Images

// Components

export default function Banner() {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box>
            <BannerContainer>
                {/* <BannerImage src={'/images/banner/online_shopping.jpg'} /> */}
                <BannerContent>
                    <Typography variant="h6">
                        Banner
                    </Typography>
                    <BannerTitle variant="h2"> Unicorn </BannerTitle>
                    <BannerDescription variant="subtitle">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nunc eget aliquam
                        ultricies
                    </BannerDescription>
                    <BannerShopButton color="primary">Shop Now</BannerShopButton>
                </BannerContent>
            </BannerContainer>
        </Box>
    );
}