export const ProductData = [
    {
        id: 1,
        name: "Product 1",
        price: 100,
        description: "This is a product",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 2,
        name: "Product 2",
        price: 200,
        description: "This is a product",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 3,
        name: "Product 3",
        price: 300,
        description: "This is a product",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 4,
        name: "Product 4",
        price: 400,
        description: "This is a product",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 5,
        name: "Product 5",
        price: 500,
        description: "This is a product",
        image: "https://via.placeholder.com/150",
    }, {
        id: 6,
        name: "Product 6",
        price: 600,
        description: "This is a product",
        image: "https://via.placeholder.com/150",
    }, {
        id: 7,
        name: "Product 7",
        price: 700,
        description: "This is a product",
        image: "https://via.placeholder.com/150",
    }, {
        id: 8,
        name: "Product 8",
        price: 800,
        description: "This is a product",
        image: "https://via.placeholder.com/150",
    }, {
        id: 9,
        name: "Product 9",
        price: 900,
        description: "This is a product",
        image: "https://via.placeholder.com/150",
    }, {
        id: 10,
        name: "Product 10",
        price: 1000,
        description: "This is a product",
        image: "https://via.placeholder.com/150",
    },
];