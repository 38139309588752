import { Box, Button, IconButton, styled } from "@mui/material";
import { Colors } from "../theme";
import { slideINBottom, slideInRight } from "../../animation";


export const Product = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        position: 'relative',
    },
}));

export const ProductImage = styled('img')(({ src, theme }) => ({
    src: `url(${src})`,
    width: '100%',
    backgroundColor: Colors.light_gray,
    padding: '10px',
    [theme.breakpoints.down('md')]: {
        width: '80%',
        padding: '24px',
    },
}));

export const ProductActionButton = styled(IconButton)(({ theme }) => ({
    background: Colors.white,
    margin: 4,
    [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: 0,
        top: '20px',
    },
}));

export const ProductFavButton = styled(ProductActionButton, {
    shouldForwardProp: (prop) => prop !== 'isFav'
})(({ isFav, theme }) => ({
    color: isFav ? Colors.primary : Colors.secondary,
    [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: 0,
        top: 10,
    },
}));

export const ProductAddToCart = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'show'
})(({ show, theme }) => ({
    width: '120px',
    fontSize: '12px',
    [theme.breakpoints.up('md')]: {
        position: 'absolute',
        bottom: '2%',
        width: '120px',
        padding: '10px 5px',
        animation: show ? `${slideINBottom} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both` : 'none',
    },
    background: Colors.secondary,
    opacity: 0.9,
}));

export const ProductMetaWrapper = styled(Box)(({ theme }) => ({
    padding: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const ProductActionWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'show'
})(({ show, theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: show ? 'flex' : 'none',
        position: 'absolute',
        bottom: '2%',
        right: 0,
        top: '20px',
        animation: show ? `${slideInRight} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both` : 'none',
    },
}));