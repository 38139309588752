import { IconButton } from "@mui/material";
import { AppBarContainer, AppBarHeader } from "../../styles/appBar";
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from "@mui/icons-material/Search";
import Actions from "./action";
import { useUiContext } from "../../context/ui";

export default function AppBarMobile({ matches }) {

    const { toggleDrawer, toggleSearch } = useUiContext();

    return (
        <AppBarContainer>
            <IconButton onClick={toggleDrawer}>
                <MenuIcon />
            </IconButton>
            <AppBarHeader>
                UNICORN
            </AppBarHeader>
            <IconButton onClick={toggleSearch}>
                <SearchIcon />
            </IconButton>
            <Actions matches={matches} />
        </AppBarContainer>
    );
}