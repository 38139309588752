import { Box, Typography, List, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../../styles/theme";

// Container
export const AppBarContainer = styled(Box)(({ theme }) => ({

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 4,
    padding: '2px 8px',
}));

// Header
export const AppBarHeader = styled(Typography)(({ theme }) => ({
    fontSize: '4em',
    flexGrow: 1,
    padding: '4px',
    fontFamily: 'Pacifico',
    color: Colors.secondary,
}));

// List
export const AppBarList = styled(List)(({ type }) => ({
    display: type === 'row' ? 'flex' : 'block',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 3,
}));

export const ActionIconsContainerMobile = styled(Box)(({ theme }) => ({
    display: 'flex',
    background: Colors.shaft,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    alignItems: 'center',
    zIndex: 99,
    borderTop: `1px solid ${Colors.border}`,
}));

export const ActionIconsContainerDesktop = styled(Box)(({ theme }) => ({
    // display: 'flex',
    // background: Colors.shaft,
    // width: '100%',
    // position: 'fixed',
    // bottom: 0,
    // left: 0,
    // alignItems: 'center',
    // zIndex: 99,
    // borderTop: `1px solid ${Colors.border}`,
    flexGrow: 0,
}));

export const DrawerCloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: 10,
    left: '250px',
    zIndex: 1999,
}));

